import { useCallback, useState } from "react";
import { MyceliumItem } from "../MyceliumItem";
import MyceliumAttempts from "../MyceliumAttempts/MyceliumAttempts";
import React from "react";
import "./mycelium-table.css";

export default function MyceliumTable({
  words,
  handleSubmit,
  handleShuffle,
  remainingAttempts,
}) {
  const [selected, setSelected] = useState([]);

  const handleClick = useCallback(
    (selectedWord, isSelected) => {
      let newSelected = [];

      if (!isSelected) {
        newSelected = selected.filter((word) => word !== selectedWord);
      } else {
        newSelected = [...selected, selectedWord];
      }

      setSelected(newSelected);
    },
    [selected]
  );

  const onSubmit = useCallback(() => {
    setSelected([]);
    handleSubmit(selected);
  }, [selected, handleSubmit]);

  const onReset = useCallback(() => {
    setSelected([]);
  }, []);

  return (
    <>
      <ul className="mycelium-table">
        {words.map((word) => (
          <MyceliumItem
            isSelected={selected.includes(word)}
            key={word}
            word={word}
            handleClick={handleClick}
            allowSelection={selected.length < 4}
          />
        ))}
      </ul>

      <MyceliumAttempts remainingAttempts={remainingAttempts} />

      <div className="buttons-container">
        <button
          type="button"
          className="primary-button"
          onClick={handleShuffle}
        >
          Shuffle
        </button>
        <button
          disabled={!Boolean(selected.length)}
          type="button"
          className="primary-button"
          onClick={onReset}
        >
          Deselect all
        </button>

        <button
          disabled={!Boolean(selected.length === 4)}
          type="button"
          className="primary-button"
          onClick={onSubmit}
        >
          Submit
        </button>
      </div>
    </>
  );
}
