import "./App.css";
import React, { useState, useCallback, useContext } from "react";
import MyceliumTable from "./components/MyceliumTable/MyceliumTable";
import MyceliumSolved from "./components/MyceliumSolved/MyceliumSolved";
import MyceliumExplanation from "./components/MyceliumExplanation";
import MyceliumHeader from "./components/MyceliumHeader";
import MyceliumFlashscreen from "./components/MyceliumFlashScreen/MyceliumFlashScreen";
import MyceliumQuiz from "./components/MyceliumQuiz/MyceliumQuiz";
import { PlayerContext, PLAYERS } from "./contexts/PlayerContext";
import { shuffleArray, wordsGroupToArray } from "./utils";
import MyceliumSuccess from "./components/MyceliumSuccess/MyceliumSuccess";
import MyceliumGreeting from "./components/MyceliumGreeting/MyceliumGreeting";
import MyceliumPopUp from "./components/MyceliumPopUp/MyceliumPopUp";

function App() {
  const playerName = useContext(PlayerContext);
  const dataSource = { ...PLAYERS[playerName] };

  const [showSuccess, setShowSuccess] = useState(false);
  const [showFlashscreen, setShowFlashscreen] = useState(true);
  const [currentUnsolved, setCurrentUnsolved] = useState({ ...dataSource });
  const [currentSolved, setCurrentSolved] = useState({});
  const [showDescription, setShowDescription] = useState(false);
  const [remainingAttempts, setRemainingAttempts] = useState(4);
  const [currentWords, setCurrentWords] = useState(
    wordsGroupToArray(currentUnsolved)
  );

  const [showMistakePopUp, setShowMistakePopUp] = useState(false);

  const handleMistakePopUp = useCallback(() => {
    setShowMistakePopUp(true);
    setTimeout(() => setShowMistakePopUp(false), 3000);
  }, []);

  const handleSubmit = useCallback(
    (selected) => {
      let success = false;

      Object.entries(currentUnsolved).forEach((group) => {
        const groupWords = group[1].words;

        if (
          JSON.stringify(groupWords.sort()) === JSON.stringify(selected.sort())
        ) {
          success = true;
          const category = group[0];

          setCurrentSolved(
            Object.assign(currentSolved, {
              [category]: dataSource[category],
            })
          );

          const updatedGroups = { ...currentUnsolved };
          delete updatedGroups[category];

          const updatedWords = wordsGroupToArray(updatedGroups);

          setCurrentWords(updatedWords);
          setCurrentUnsolved(updatedGroups);

          if (!updatedWords.length) {
            setShowSuccess(true);

            return;
          }
        }
      });

      if (!success) {
        setRemainingAttempts(remainingAttempts - 1);

        if (remainingAttempts - 1 > 0) {
          handleMistakePopUp();
        }
      }
    },
    [
      currentUnsolved,
      currentSolved,
      remainingAttempts,
      dataSource,
      handleMistakePopUp,
    ]
  );

  const handleShuffle = useCallback(() => {
    const currentUnsolvedWords = wordsGroupToArray(currentUnsolved);
    setCurrentWords(shuffleArray(currentUnsolvedWords));
  }, [currentUnsolved]);

  const onFlashScreenGo = () => {
    setShowFlashscreen(false);
    setShowDescription(true);
  };

  const onResetGame = () => {
    const words = wordsGroupToArray({ ...dataSource });

    setShowSuccess(false);
    setCurrentUnsolved({ ...dataSource });
    setCurrentWords(words);
    setCurrentSolved({});
  };

  return (
    <PlayerContext.Provider value={playerName}>
      <div className="App">
        {showSuccess && <MyceliumSuccess handleBackToGameClick={onResetGame} />}
        {showFlashscreen && (
          <MyceliumFlashscreen handleClick={onFlashScreenGo} />
        )}
        {!Boolean(showFlashscreen) && (
          <MyceliumHeader handleClick={() => setShowDescription(true)} />
        )}
        {showDescription && (
          <MyceliumExplanation handelClick={() => setShowDescription(false)} />
        )}
        {!Boolean(showFlashscreen) && Boolean(remainingAttempts) && (
          <div>
            <MyceliumGreeting>Hey, {playerName}!</MyceliumGreeting>
            <p className="game-intro">Create groups of four!</p>
          </div>
        )}
        {Boolean(remainingAttempts) && !Boolean(showFlashscreen) && (
          <MyceliumSolved solved={currentSolved} />
        )}
        {Boolean(remainingAttempts) && !Boolean(showFlashscreen) && (
          <>
            <MyceliumTable
              words={currentWords}
              handleSubmit={handleSubmit}
              handleShuffle={handleShuffle}
              remainingAttempts={remainingAttempts}
            />
          </>
        )}
        {!Boolean(remainingAttempts) && (
          <MyceliumQuiz
            name={playerName}
            handleSubmitClick={() => setRemainingAttempts(4)}
          />
        )}
        {showMistakePopUp && <MyceliumPopUp isVisible={showMistakePopUp} />}
      </div>
    </PlayerContext.Provider>
  );
}

export default App;
