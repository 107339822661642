import React from "react";
import "./mycelium-button.css";

export default function MyceliumButton({
  children,
  onClick,
  disabled = false,
  variation,
}) {
  return (
    <button
      type="button"
      disabled={disabled}
      onClick={onClick}
      className={`mycelium-button mycelium-button${
        variation ? "--" + variation : ""
      }`}
    >
      {children}
    </button>
  );
}
