import React from "react";

export default function MyceliumExplanation({ handelClick }) {
  return (
    <>
      <div className="popup-overlay" />
      <div className="popup-description">
        <header className="popup-header">
          <button type="button" className="popup-close" onClick={handelClick}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="24"
              fill="none"
            >
              <path
                fill="#031F11"
                d="M8.34 23.208H.597V22.2h2.75l6.461-9.435L1.833 1.01H0V0h12.053v1.009H9.76l5.225 7.72 5.59-7.72H16.91V0h7.7v1.009h-2.75l-6.324 8.577 8.57 12.613h1.833v1.01H13.932v-1.01h2.291l-5.774-8.476-5.82 8.476H8.34v1.01Z"
              />
            </svg>
          </button>
        </header>
        <div className="popup-content">
          <h2 style={{ marginBottom: 20, fontSize: 18 }}>
            How to play Mycelium
          </h2>
          <div className="dotted-separator" />
          <h3 style={{ fontSize: 16 }}>Make groups of four</h3>
          <ul>
            <li>
              <p>
                1. Select four items and tap 'Submit' to check if your guess is
                correct.
              </p>
            </li>
            <li>
              <p>2. Find the groups without making 4 mistakes!</p>
            </li>
          </ul>

          <div className="dotted-separator" />

          <h3 style={{ fontSize: 16 }}>Category Examples</h3>
          <ul>
            <li>
              <p>
                FISH <br /> Bass, Flounder, Salmon, Trout
              </p>
            </li>
            <li>
              <p>
                FIRE <br /> Ant, Drill, Island, Opal
              </p>
            </li>
          </ul>
          <div className="dotted-separator" style={{ margin: "15px 0" }} />
          <p style={{ fontSize: 14, lineHeight: "20px" }}>
            Categories will always be more specific than "5-LETTER WORDS,"
            "NAMES" or "VERBS."
          </p>
          <div className="dotted-separator" style={{ margin: "15px 0" }} />
          <p style={{ fontSize: 14, lineHeight: "20px" }}>
            Each group is assigned a color, which will be revealed as you solve.
          </p>
          <div className="dotted-separator" style={{ margin: "15px 0" }} />
          <p style={{ fontSize: "14px", lineHeight: "20px", color: "#EA8058" }}>
            We hope you'll enjoy this game, it was made just for you! Love, Gal
            & Mo
          </p>
        </div>
      </div>
    </>
  );
}
