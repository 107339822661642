import { createContext } from "react";
import gefen from "../data/gefen.json";
import chloe from "../data/chloe.json";
import laura from "../data/laura.json";

export const PLAYERS = {
  gefen,
  chloe,
  laura,
};

let playerFromUrl = window.location.pathname.replace("/", "");

if (!Object.keys(PLAYERS).includes(playerFromUrl)) {
  playerFromUrl = "gefen";
}

export const PlayerContext = createContext(playerFromUrl);
