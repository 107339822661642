import React, { useState, useContext } from "react";
import "./mycelium-quiz.css";
import { PlayerContext } from "../../contexts/PlayerContext";
import MyceliumButton from "../MyceliumButton/MyceliumButton";
import MyceliumGreeting from "../MyceliumGreeting/MyceliumGreeting";

export default function MyceliumQuiz({ handleSubmitClick }) {
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const playerName = useContext(PlayerContext);

  return (
    <div className="mycelium-quiz">
      <MyceliumGreeting>Fuck, {playerName}!</MyceliumGreeting>
      <p className="quiz-intro">
        Looks like you're out of mistakes
        <br /> Answer this question to try again:
      </p>
      <span className="dotted-separator" />
      <h4 className="quiz-question">
        According to a lie detector test, who's Kris's favourite daughter?
      </h4>
      <div className="quiz-photos">
        <div
          className="quiz-photo photo-1"
          onClick={() => setSelectedAnswer("1")}
        >
          {selectedAnswer === "1" && (
            <span className="photo-message">Nope</span>
          )}
        </div>
        <div
          className="quiz-photo photo-2"
          onClick={() => setSelectedAnswer("2")}
        >
          {selectedAnswer === "2" && (
            <span className="photo-message">Yep!</span>
          )}
        </div>
        <div
          className="quiz-photo photo-3"
          onClick={() => setSelectedAnswer("3")}
        >
          {selectedAnswer === "3" && (
            <span className="photo-message">Nope</span>
          )}
        </div>
      </div>
      <MyceliumButton
        disabled={selectedAnswer !== "2"}
        onClick={handleSubmitClick}
      >
        Let's go again!
      </MyceliumButton>
    </div>
  );
}
