import React from "react";
import "./mycelium-attempts.css";

export default function MyceliumAttempts({ remainingAttempts }) {
  return (
    <div className="mycelium-attempts-container">
      <p className="attempts-label">Mistakes remaining:</p>
      <ul className="mycelium-attempts">
        {Array.from(Array(remainingAttempts)).map((attempt, index) => (
          <li className="remaining-attempt" key={index}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="19"
              fill="none"
            >
              <path
                fill="#DD8943"
                fillRule="evenodd"
                d="M9.442 0A9.442 9.442 0 0 0 0 9.442a3.478 3.478 0 0 0 3.478 3.478h2.536c.041.398.066.786.066 1.14 0 .356-.025.746-.067 1.147C5.813 17.153 7.222 19 9.18 19h2.162c1.957 0 3.367-1.847 3.166-3.793-.042-.4-.067-.791-.067-1.147 0-.354.025-.742.066-1.14h1.776a3.479 3.479 0 0 0 3.478-3.478A9.442 9.442 0 0 0 10.318 0h-.876Z"
                clipRule="evenodd"
              />
            </svg>
          </li>
        ))}
      </ul>
    </div>
  );
}
