import React from "react";
import "./mycelium-pop-up.css";

export default function MyceliumPopUp({ isVisible }) {
  return (
    <p
      className={`mycelium-pop-up mycelium-pop-up${
        isVisible ? "--visible" : ""
      }`}
    >
      Try Again!
    </p>
  );
}
