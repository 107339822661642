import React, { useContext } from "react";
import "./mycelium-success.css";
import { PlayerContext } from "../../contexts/PlayerContext";
import Lottie from "lottie-react";
import animation from "./animation.json";
import MyceliumButton from "../MyceliumButton/MyceliumButton";
import MyceliumGreeting from "../MyceliumGreeting/MyceliumGreeting";

export default function MyceliumSuccess({
  handleBackToGameClick,
  handleTryGamesClick,
}) {
  const playerName = useContext(PlayerContext);

  return (
    <div className="mycelium-success">
      <MyceliumGreeting className="success">
        Yay, {playerName}!
      </MyceliumGreeting>
      <p className="success-intro">You've made it!</p>
      <Lottie
        className="success-animation"
        animationData={animation}
        loop={true}
      />

      <p className="success-holiday-greeting">
        Happy Holidays! <br /> Love, Gal & Mo
      </p>
      <div className="success-buttons-group">
        <MyceliumButton variation="secondary" onClick={handleTryGamesClick}>
          Try someone else's game
        </MyceliumButton>
        <MyceliumButton variation="outline" onClick={handleBackToGameClick}>
          Back to my game
        </MyceliumButton>
      </div>
    </div>
  );
}
