import React from "react";
import "./mycelium-greeting.css";

export default function MyceliumGreeting({ children, className }) {
  return (
    <h3
      className={`mycelium-greeting ${
        className ? "mycelium-greeting--" + className : ""
      }`}
    >
      {children}
    </h3>
  );
}
