import { useCallback } from "react";
import React from "react";

export const MyceliumItem = ({ isSelected, word, handleClick, allowSelection }) => {
    const onClick = useCallback(() => {
        if (!isSelected && !allowSelection) return;

        handleClick(word, !isSelected);
    }, [handleClick, isSelected, word, allowSelection]);


    return <li className={`mycelium-item ${isSelected ? 'selected' : ''}`} onClick={onClick}><p>{word}</p></li>;
}