import React from "react";
import "./mycelium-flash-screen.css";
import MyceliumButton from "../MyceliumButton/MyceliumButton";

export default function MyceliumflashScreen({ handleClick }) {
  return (
    <div className="mycelium-flash-screen">
      <div className="mycelium-flash-screen-content">
        <div className="logo-intro-container">
          <div className="mycelium-logo"></div>
          <p className="mycelium-intro">
            A customised word game by Gal & Mo. Happy holidays!
          </p>
        </div>
        <div className="leaves-button-container">
          <div className="mycelium-leaves"></div>
          <MyceliumButton onClick={handleClick}>Let's go!</MyceliumButton>
        </div>
      </div>
    </div>
  );
}
