import React from "react";

export default function MyceliumHeader({ handleClick }) {
  return (
    <header className="mycelium-header">
      <button type="button" className="show-explanation" onClick={handleClick}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="24"
          fill="none"
        >
          <path
            fill="#FFEFBB"
            d="M7.975 0c3.58 0 6.184.812 7.809 2.437.909.947 1.308 2.057 1.198 3.33-.11 1.245-.523 2.26-1.24 3.045a6.987 6.987 0 0 1-2.479 1.706c-.909.352-1.804.69-2.685 1.015-2.011.785-2.824 2.017-2.438 3.695h-.703c-1.735-.974-2.506-2.233-2.313-3.776.082-.677.399-1.3.95-1.868a7.539 7.539 0 0 1 1.735-1.422 9.637 9.637 0 0 0 1.736-1.3c.578-.54.936-1.096 1.074-1.664.33-1.408.124-2.437-.62-3.086-.744-.677-1.625-1.016-2.644-1.016-1.02 0-1.997.19-2.934.569-.936.379-1.405.744-1.405 1.096 0 .325.138.61.413.853.276.217.579.393.91.528.33.135.633.393.909.772.275.379.413.88.413 1.502 0 .596-.262 1.137-.785 1.625-.524.487-1.171.73-1.942.73-.772 0-1.46-.31-2.066-.933C.289 7.215 0 6.443 0 5.523 0 3.98.73 2.68 2.19 1.624 3.677.541 5.606 0 7.975 0Zm-.042 24a3.694 3.694 0 0 1-2.685-1.096c-.744-.731-1.116-1.611-1.116-2.64 0-1.056.372-1.95 1.116-2.68a3.693 3.693 0 0 1 2.685-1.097c1.075 0 1.984.366 2.727 1.097.744.73 1.116 1.624 1.116 2.68 0 1.029-.372 1.909-1.116 2.64C9.917 23.634 9.008 24 7.933 24Z"
          />
        </svg>
      </button>
    </header>
  );
}
