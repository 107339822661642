import React from "react";
import "./mycelium-solved.css";

export default function MyceliumSolved({ solved }) {
  return (
    <div className="mycelium-solved">
      {Object.keys(solved).map((solvedItem) => (
        <div key={solvedItem} className={`${solvedItem} solved-block`}>
          <p className="solved-explanation">{solved[solvedItem].explanation}</p>
          <p className="solved-words">{solved[solvedItem].words.join(", ")}</p>
        </div>
      ))}
    </div>
  );
}
